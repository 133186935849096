import { FC, ReactNode, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { MobileNumberBackendValidationErrorMap, formatMobileNumber, validateMobileNumber } from "helpers/patientUtils";

import { IFormErrorDetails } from "model/formError";
import HttpStatus from "model/httpStatus";
import {
    IMobileNumberAndCountryCode,
    MFAButtons,
    MFAVerificationChannel,
    OTP_LENGTH,
    otpGenericError,
} from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";
import getMFASetupState from "redux/selectors/mfa";

import userService from "services/userService";

import "scss/PhoneNumberRegistration.scss";

interface Input {
    countryCode: string;
    mobileNumber: string;
}

const PhoneNumberRegistration: FC = () => {
    const [hasAPIError, setHasAPIError] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<IFormErrorDetails[]>([]);
    const loading = useSelector(getPendingRequest);
    const mfaState = useSelector(getMFASetupState);
    const destination = mfaState[MFAVerificationChannel.SMS].destination as IMobileNumberAndCountryCode;
    const defaultValues = useMemo(
        () => ({
            defaultValues: {
                mobileNumber: destination?.mobileNumber || "",
                countryCode: destination?.countryCode || "+44",
            },
        }),
        []
    );

    const {
        clearErrors,
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setError,
        setValue,
    } = useForm<Input>(defaultValues);

    const handleMobileNumberValidation = (): boolean => {
        setValidationError([]);
        clearErrors();
        const mobileNumber = getValues("mobileNumber");
        const { isError, message } = validateMobileNumber({
            mobileNumber,
            validateForMFA: true,
        });
        if (!isError) {
            return true;
        }
        setError("mobileNumber", { message });
        return false;
    };

    /**
     * Hack to prevent user entering full number in mui-tel-input
     */
    const handleCountryCodeChange = (value: string, info: MuiTelInputInfo): void => {
        if (info.numberValue === `+${info.countryCallingCode}`) {
            setValue("countryCode", `+${info.countryCallingCode}`);
        }
    };

    const onSubmitInvalid: SubmitErrorHandler<Input> = (): void => {
        handleMobileNumberValidation();
    };

    const onSubmit: SubmitHandler<Input> = async (data: Input): Promise<void> => {
        const { countryCode, mobileNumber } = data;
        const concatenatedMobileNumber = formatMobileNumber(mobileNumber, countryCode);

        try {
            setValidationError(null);
            setHasAPIError(false);
            clearErrors();
            await userService.initiateSMSVerification({
                countryCode,
                mobileNumber,
                concatenatedMobileNumber,
            });
        } catch (error: any) {
            if (
                error?.response?.status === HttpStatus.BAD_REQUEST &&
                error?.response?.data?.errors[0]?.details?.length
            ) {
                setValidationError(error?.response?.data?.errors[0]?.details);
            } else {
                setHasAPIError(true);
            }
        }
    };

    const helperText: ReactNode | string = validationError?.length ? (
        <div>
            {validationError.map((error) => (
                <div>{MobileNumberBackendValidationErrorMap[error?.errorDescription]}</div>
            ))}
        </div>
    ) : (
        ""
    );

    return (
        <Box>
            <h2>Now, enter your mobile number</h2>
            <p>We will send you a {OTP_LENGTH}-digit code to verify your mobile number</p>
            <Box
                className="full-width phone-number-registration-container"
                component="form"
                onSubmit={handleSubmit(onSubmit, onSubmitInvalid)}
            >
                <Box className="phone-number-registration-form">
                    <Controller
                        name="countryCode"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <FormControl className="country-code-control">
                                <MuiTelInput
                                    {...field}
                                    className="country-code-selector"
                                    onChange={(value, info) => handleCountryCodeChange(value, info)}
                                    defaultCountry="GB"
                                    label="Country code"
                                    focusOnSelectCountry
                                    color="primary"
                                    contentEditable={false}
                                />
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                            onBlur: handleMobileNumberValidation,
                            validate: handleMobileNumberValidation,
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mobile-number-input"
                                id="mobile-number"
                                name="mobileNumber"
                                value={getValues("mobileNumber")}
                                label="Mobile number"
                                error={!!helperText || !!errors?.mobileNumber?.message}
                                helperText={helperText || errors?.mobileNumber?.message}
                                inputProps={{
                                    "aria-atomic": "true",
                                    "aria-label": "Enter your mobile number",
                                    "aria-invalid": !!helperText || !!errors?.mobileNumber,
                                    "inputMode": "numeric",
                                }}
                                variant="outlined"
                            />
                        )}
                    />
                </Box>
                <Box className="mobile-number-error">
                    {hasAPIError ? (
                        <CustomMessage
                            message={otpGenericError.message}
                            showDefaultHelp
                            type={CustomMessageType.ERROR}
                        />
                    ) : null}
                </Box>
                <Box className="mfa-button-wrapper float-bottom">
                    <Button className="full-width" type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size="16px" /> : MFAButtons.CONTINUE}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PhoneNumberRegistration;

import React, { FC, ReactNode } from "react";
import { match, matchPath, useLocation, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { ErrorBoundary } from "react-error-boundary";

import FatalError from "components/ErrorBoundary/errorBoundary";
import PageWrapper from "components/templates/PageWrapper";
import Header from "components/templates/header";
import Footer from "components/templates/Footer";

import { REMOTE_MODEL_CASES_START, REMOTE_MODEL_PASSWORD } from "navigation/remoteModelRoutes";
import * as ROUTES from "navigation/routes";

interface IPageContainer {
    children: ReactNode;
    isPublic: boolean;
}

interface IPath {
    path?: string;
}

const REMOTE_MODEL_PATHS = [
    REMOTE_MODEL_CASES_START,
    REMOTE_MODEL_PASSWORD,
    ROUTES.LOGIN,
    ROUTES.RESET_PASSWORD,
    ROUTES.HOME,
];

const MFA_PATHS = [ROUTES.MFA_LOGIN];

const PageContainer: FC<IPageContainer> = ({ children, isPublic }) => {
    const location = useLocation();
    const { pathname } = location;
    const isTestPage = useRouteMatch(ROUTES.TEST)?.isExact;

    const currentMatch: match | null = isPublic ? matchPath(pathname, { path: "/:path" }) : null;
    const params: IPath | undefined = currentMatch?.params;
    const isRemoteModelOrMFAPath = params ? [...REMOTE_MODEL_PATHS, ...MFA_PATHS].includes(`/${params.path}`) : false;
    const withStaticFooter = params ? !isRemoteModelOrMFAPath : false;

    return (
        <div className={clsx("main-container", withStaticFooter && "with-static-footer")}>
            {!withStaticFooter && <Header />}

            <PageWrapper
                isRemoteModelOrMFA={isRemoteModelOrMFAPath}
                isInitialRemoteModelPage={isPublic && !withStaticFooter}
                isTestPage={isTestPage}
            >
                <ErrorBoundary FallbackComponent={FatalError}>{children}</ErrorBoundary>

                {!isTestPage ? <Footer /> : null}
            </PageWrapper>
        </div>
    );
};

export default PageContainer;

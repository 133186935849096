import { AxiosResponse } from "axios";

import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";
import { HttpConnector } from "dao/http/httpConnector";

import LocalStorageKeys from "model/localStorageKeys";
import { UserRole } from "model/userRole";

import { history } from "App";
import { REMOTE_MODEL_CASES_START } from "navigation/remoteModelRoutes";
import * as ROUTES from "navigation/routes";

import * as actions from "redux/actions";
import store, { Dispatch } from "redux/store";
import { getShowMFAFlow } from "redux/selectors/mfa";

import userService from "services/userService";
import * as ErrorService from "services/errorService";
import { LocalStorageService } from "services/localStorageService";
import organizationService from "services/organizationService";
import { TokenService } from "services/tokenService";

import URLs from "services/urls";

const {
    LOGIN_URL,
    LOGOUT_URL,
    SWITCH_USER_ORGANISATION_URL,
    CHANGE_PASSWORD_URL,
    RESET_PASSWORD_URL,
    SET_PASSWORD_URL,
    UPDATE_PASSWORD_URL,
    INIT_RESET_PASSWORD_URL,
    SEND_PASSWORD_EMAIL_VERIFICATION,
} = URLs;

function isSignedIn(signedIn?: boolean) {
    let isUserSignedIn: boolean = signedIn;

    if (isUserSignedIn === undefined) {
        isUserSignedIn = store.store.getState().auth.signedIn;
    }
    const { data } = store.store.getState();

    return isUserSignedIn && !!data.organisation && !!data.user;
}

function initPasswordReset(email: string, showUpdatePasswordPrompt?: boolean): (dispatch: any) => void {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendPost(INIT_RESET_PASSWORD_URL, { email }).then((response) => {
            LocalStorageService.cleanLastUrl();
            LocalStorageService.addItem(LocalStorageKeys.HAS_LOGGED_OUT, true);
            dispatch(actions.authActions.clearData());
            if (showUpdatePasswordPrompt) {
                dispatch(actions.authActions.updatePasswordShowPrompt());
            }
            history.push(`${ROUTES.RESET_PASSWORD}?token=${response.data.data}`);
        });
    };
}

async function homeScreenInitialisation(dispatch: any): Promise<void> {
    await userService.getLoggedUserDetailsAsync(dispatch);
    dispatch(actions.authActions.loginSuccess());
    const patientToCreateNewCase = userService.getPatientToCreateNewCase();
    const isCurrentUserPatient = userService.checkUserHasRole([UserRole.PATIENT]);
    const redirectToCaseCreation = patientToCreateNewCase && isCurrentUserPatient;

    const localStorageLastUrl = !redirectToCaseCreation ? LocalStorageService.getLastUrl() : "";
    const redirectToCaseDescription =
        userService.checkUserHasRole([UserRole.CLINICAL_VIEWER, UserRole.DERMATOLOGIST]) &&
        ROUTES.CASE_DESCRIPTION_REGEXP.test(localStorageLastUrl);

    const nextUrl = redirectToCaseDescription ? localStorageLastUrl : ROUTES.HOME;
    const defaultUrl = redirectToCaseCreation ? REMOTE_MODEL_CASES_START : nextUrl;
    LocalStorageService.cleanLastUrl();

    history.push(defaultUrl);
}

function login(email: string, password: string) {
    return async (dispatch: any) => {
        dispatch(actions.authActions.loginRequest());
        dispatch(actions.validateActions.clearData());

        try {
            const loginResponse = await HttpConnector.sendPost(LOGIN_URL, { login: email, password });
            dispatch(
                actions.authActions.setToken({
                    authTokenBody: TokenService.decodeToken(loginResponse.headers.token),
                    refreshToken: loginResponse.headers["refresh-token"],
                    token: loginResponse.headers.token,
                })
            );
            const currentUserRole = userService.getLoggedUserRole();
            const updatePasswordResponse = await AuthorizedHttpConnector.sendPost(UPDATE_PASSWORD_URL, {
                userRole: currentUserRole,
                password,
            });
            const isPasswordUpdateRequired = updatePasswordResponse.data.data;
            if (isPasswordUpdateRequired) {
                dispatch(initPasswordReset(email, true));
                return;
            }
            await userService.setMFASetup(dispatch);
            const state = store.store.getState();
            const showMFAFlow = getShowMFAFlow(state);
            if (showMFAFlow) {
                history.push(ROUTES.MFA_LOGIN);
            } else {
                homeScreenInitialisation(dispatch);
            }
        } catch (err) {
            dispatch(actions.authActions.loginFail());
            ErrorService.handleLoginScreenError(err, dispatch);
        }
    };
}

function logout() {
    return async (dispatch: any) => {
        try {
            dispatch(actions.authActions.updatePasswordReset());
            await AuthorizedHttpConnector.sendPost(LOGOUT_URL, {});
            LocalStorageService.cleanLastUrl();
            LocalStorageService.addItem(LocalStorageKeys.HAS_LOGGED_OUT, true);
            dispatch(actions.authActions.clearData());
        } catch (err) {
            dispatch(actions.authActions.clearData());
            ErrorService.handleError(err, dispatch);
        }
    };
}

function clearPasswordChange() {
    return (dispatch: any) => {
        dispatch(actions.authActions.clearPasswordResponse());
    };
}

function changePassword(oldPassword: string, newPassword: string, newPasswordRepeated: string) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendPost(CHANGE_PASSWORD_URL, {
            newPassword,
            newPasswordRepeated,
            oldPassword,
        })
            .then((response) => {
                ErrorService.cleanFormError();
                dispatch(actions.authActions.changePasswordSuccess(response));
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

async function requestEmailSend(email: string): Promise<AxiosResponse> {
    const response = await HttpConnector.sendPost(RESET_PASSWORD_URL, { email });

    ErrorService.cleanFormError();
    return response.data;
}

function setPassword(token: string, newpassword: string, newpasswordconf: string) {
    return HttpConnector.sendPost(`${SET_PASSWORD_URL}?token=${token}`, {
        newPassword: newpassword,
        newPasswordRepeated: newpasswordconf,
    });
}

async function checkToken(token: string): Promise<AxiosResponse> {
    try {
        const result = await HttpConnector.sendGet(`${RESET_PASSWORD_URL}?token=${token}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

function changePasswordReset() {
    return (dispatch: any) => {
        dispatch(actions.authActions.changePasswordReset());
    };
}

async function sendPasswordEmailVerification(email: string): Promise<AxiosResponse> {
    const response = await HttpConnector.sendPost(SEND_PASSWORD_EMAIL_VERIFICATION, {
        email,
    });

    return response.data;
}

function getUserRoleFromToken(token: string): UserRole {
    const decodedToken = TokenService.decodeToken(token);
    return decodedToken.userRole;
}

function switchUserOrganisation(organisationUuid: string) {
    return async (dispatch: Dispatch) => {
        try {
            const response = await AuthorizedHttpConnector.sendPost(SWITCH_USER_ORGANISATION_URL, {
                organisationUuid,
            });

            dispatch(
                actions.authActions.setToken({
                    authTokenBody: TokenService.decodeToken(response.headers.token),
                    refreshToken: response.headers["refresh-token"],
                    token: response.headers.token,
                })
            );
            await organizationService.getOrganisation(dispatch);
            dispatch(actions.dataActions.hideOrganisationSelection());
        } catch (error) {
            ErrorService.handleError(error, dispatch);
        }
    };
}

export {
    changePassword,
    changePasswordReset,
    checkToken,
    clearPasswordChange,
    initPasswordReset,
    isSignedIn,
    login,
    logout,
    requestEmailSend,
    setPassword,
    sendPasswordEmailVerification,
    switchUserOrganisation,
    getUserRoleFromToken,
    homeScreenInitialisation,
};

import { FC, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { handleKeyDown } from "helpers/input";

import { IErrorResponse } from "model/errors";
import HttpStatus from "model/httpStatus";
import {
    IMobileNumberAndCountryCode,
    IOTPConfig,
    MFAUserFriendlyChannel,
    MFAVerificationChannel,
    otpGenericError,
} from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";
import getMFASetupState from "redux/selectors/mfa";

import userService from "services/userService";

import "scss/MFASetup.scss";

interface IResendOTPInstruction {
    config: IOTPConfig;
    code: string;
    to: string;
    handleNewCodeSent: (isSuccessful: boolean) => void;
}

const ResendOTPInstruction: FC<IResendOTPInstruction> = ({ config, code, to, handleNewCodeSent }) => {
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);
    const loading = useSelector(getPendingRequest);
    const mfaState = useSelector(getMFASetupState);
    const destination = mfaState[MFAVerificationChannel.SMS].destination as IMobileNumberAndCountryCode;
    const { title, channel } = config;

    const requestNewOTP = async (): Promise<void> => {
        try {
            await userService.cancelVerificationAttempt({ channel, code, destination: to });
            if (channel === MFAVerificationChannel.EMAIL) {
                await userService.initiateEmailVerification();
            } else {
                await userService.initiateSMSVerification({ ...destination });
            }
            handleNewCodeSent(false);
        } catch (error: any) {
            if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
                setApiErrorResponse({
                    message: error?.response?.data?.errors[0].message,
                    details: error?.response?.data?.errors[0]?.details?.[0].errorDescription,
                });
            } else {
                setApiErrorResponse({
                    message: otpGenericError.message,
                });
            }
        }
    };

    return (
        <Box>
            <h2>{title}</h2>
            <p className="otp-form-instructions">
                There was an issue with your previous code. Request a new one to try again.
            </p>
            <Box className="resend-code-content">
                <CustomMessage
                    type={CustomMessageType.INFO}
                    message="Remember that the code is only valid for 10 minutes"
                />
                {apiErrorResponse ? (
                    <CustomMessage
                        type={CustomMessageType.ERROR}
                        message={apiErrorResponse.message}
                        cta={apiErrorResponse?.details}
                        showDefaultHelp={!apiErrorResponse?.details}
                    />
                ) : null}
            </Box>
            <Box className="mfa-button-wrapper">
                <Button
                    className="full-width sentence-case"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={requestNewOTP}
                    onKeyDown={(e) => handleKeyDown(e, requestNewOTP)}
                >
                    {loading ? (
                        <CircularProgress size="16px" />
                    ) : (
                        `Resend ${MFAUserFriendlyChannel[channel]} with new code`
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default ResendOTPInstruction;
